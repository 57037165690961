import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/register",
        name: "Register",
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "register" */ "../views/Register.vue"),
    },
    {
        path: "/password",
        name: "Password",
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "password" */
                "../views/ForgottenPassword.vue"
            ),
    },
    {
        path: "/cjQErNxjYdP3azuL9LwOsapFR052",
        name: "Churchill",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/Churchill.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/PlmaY66Ge4SaTsndtBsQGP0DAot1",
        name: "ElisabethTheChef",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/ElisabethTheChef.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/6RF1m9FkG9UTYFf7iJ7hUjzrdfg2",
        name: "TrentControls",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/TrentControls.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/iG9jLyygrIWe8QvUNN0G9NbeiPS2",
        name: "Steelite",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/Steelite.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/HIpIrDmT2DSAnxfb7GSBtfXoxQj1",
        name: "Kingspan",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/Kingspan.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/UORhhGMcqBOV9fIpcZrtJwP1QW43",
        name: "Kingspan",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/Kingspan_user2.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/4JFPQMlkVffMYDiSyULGX27uz9d2",
        name: "NGF",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/NGF.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/i0L6wOuhzoRtBdkZhAgAPlMEEwF2",
        name: "NGF",
        component: () =>
            import ( /* webpackChunkName: "password" */ "../views/NGF_user2.vue"),
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});
router.beforeEach(async(to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const uid = localStorage.getItem("ces-uid");
    if (requiresAuth && !uid) {
        next("/");
    } else {
        next();
    }
});

export default router;