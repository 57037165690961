import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCiltgKvpkZaeR6IQF81ekjB6LSIiw2DRc",
  authDomain: "ces-customer-portal.firebaseapp.com",
  projectId: "ces-customer-portal",
  storageBucket: "ces-customer-portal.appspot.com",
  messagingSenderId: "529647804489",
  appId: "1:529647804489:web:894cfd137aacfcad9a5f98",
  measurementId: "G-5MTR0NTZQH",
};

initializeApp(firebaseConfig);

const auth = getAuth();

export const getCurrentUser = () => {
  const user = auth.currentUser;
  if (user) {
    return user;
  } else {
    return "DAVE";
  }
};

export const loginEmailPassword = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.log(error);
  }
};
export const logout = async () => {
  await signOut(auth);
};

export const requestPaswordReset = async (email) => {
    console.log(email);
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return false;
  }
};
