<template>
  <div class="w-full p-0 m-0">
    <div class="headerBG flex flex-col items-center justify-center">
      <div class="logoBG flex flex-col items-center justify-center">
        <img class="logoImg" src="../assets/Logo_Wide.webp" alt="" />
        <div
          class="lg:text-4xl text-2xl text-black p-4"
          style="font-family: Montserrat; font-weight: 300"
        >
          Bespoke Engineering For Industry
        </div>
      </div>
    </div>
    <h1 class="lg:text-6xl text-3xl text-black lg:p-12 p-4 bg-white w-full">
      {{ text }}
    </h1>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: ["text"],
};
</script>
<style>
.headerBG {
  background-image: url("../assets/Cogs_BG.webp");
  background-size: cover;
  width: 100%;
  background-position: center;
  padding: 100px 0px;
}
.logoBG {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  padding: 50px 0px;
}
.logoImg{
  height:auto !important
}
</style>
